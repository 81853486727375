@charset "utf-8";

// Dimensions
$max-content-width: 1200px;
$profile-position-h: 50%;
$profile-position-v: 0%;

@import
  "variables",
  "themes",
  "layout",
  "base",
  "distill"
;
